import { useState } from "react";
import "./index.css";
import Logo from "../../Assets/small-logo.svg";
import { Drawer } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../Button/Index";
function TopHead() {
  const [open, setOpen] = useState(false);
  const openSidebar = () => {
    setOpen(!open);
  };

  const location = useLocation();
  const history = useHistory();
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");

  console.log(locationA);
  console.log(locationB);
  return (
    <>
      <div className="head-primary-bg fixed-top show-web-interface">
        <div className="container-fluid-x-head">
          <div className="">
            <div className="d-flex justify-content-between align-items-center">
              <div
                className=""
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <img src={Logo} width="50px" />
              </div>
              <div className="">
                <div className="d-flex align-items-center">
                  <div
                    className="public-font pt-2 pr-4"
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "30px",
                    }}
                  >
                    EDD Home
                  </div>
                  <div
                    className="public-font pt-2 pr-4"
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "30px",
                    }}
                  >
                    Help
                  </div>
                  {sessionStorage?.getItem("name") && (
                    <div
                      className="public-font pt-2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "30px",
                      }}
                    >
                      Welcome, {sessionStorage?.getItem("name")}
                    </div>
                  )}
                  {sessionStorage?.getItem("name") && (
                    <div
                      className="public-font pt-2 pl-4"
                      style={{
                        fontSize: "16px",
                        fontWeight: "300",
                        lineHeight: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage?.clear();
                        localStorage.clear();
                        window.location.href = "/";
                      }}
                    >
                      Log Out
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          sessionStorage?.getItem("name")
            ? "head-primary-full show-mobile-interface"
            : "head-primary-bg fixed-top show-mobile-interface"
        }
      >
        <div className="container-fluid-x-head">
          <div className="">
            <div className="d-flex justify-content-between align-items-center">
              <div
                className=""
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <img src={Logo} width="50px" />
              </div>
              <div className="d-flex align-items-center">
                <div className="text-white">
                  <i className="fa fa-globe pr-2"></i>English
                </div>
                <div className="text-white pl-4">EDD Home</div>
                <div className="text-white pl-4">Help</div>
              </div>
            </div>
            {sessionStorage?.getItem('name') &&
            <div className="d-flex justify-content-end align-items-center">
              <div>
                <div className=" d-flex align-items-center">
                  <div
                    className="public-font pt-2"
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "30px",
                    }}
                  >
                    Welcome, {sessionStorage?.getItem("name")}
                  </div>
                  <div
                    className="public-font pt-2 pl-4"
                    style={{
                      fontSize: "16px",
                      fontWeight: "300",
                      lineHeight: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      sessionStorage?.clear();
                      localStorage.clear();
                      window.location.href = "/";
                    }}
                  >
                    Log Out
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {sessionStorage?.getItem('name') &&
      <div>
        <br />
      </div>}
      <Drawer
        title={false}
        placement="right"
        onClose={() => {
          setOpen(false);
        }}
        visible={open}
        width={"90%"}
        closable={false}
      >
        <div className="text-right" onClick={() => setOpen(false)}>
          <i className="fa fa-2x fa-times-circle text-danger"></i>
        </div>
        <div
          className="text-center mt-5 "
          style={{ color: "#5928e5" }}
          onClick={() => {
            history.push("/");
          }}
        >
          HOME
        </div>

        <div
          className="text-center pt-3"
          style={{ color: "#5928e5" }}
          onClick={() => {
            history.push("/faqs");
          }}
        >
          FAQs
        </div>
        <div
          className="text-center pt-3"
          style={{ color: "#5928e5" }}
          onClick={() => {
            history.push("/blogs");
          }}
        >
          BLOGS
        </div>

        <br />
      </Drawer>
    </>
  );
}

export default TopHead;
