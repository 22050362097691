import { forwardRef } from "react";
import "./index.css";
export const Input = forwardRef((props, ref) => {
  const style = {
    ...props.style,
    paddingLeft: props.prependIcon ? "0.4rem" : "0.4rem",
    height: "40px",
    outLine: "0",
    padding: "12px 15px",
    fontSize:'16px',
    marginTop: "-.6rem",
    lineHeight:'30px',
    borderRadius:0,
    fontWeight:'400',
    color: 'rgb(33, 37, 41)',
  };
  return (
    <div>
      <label
        className="public-font pb-2"
        htmlFor={props.name}
        style={{
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "30px",
          color: "rgb(51,51,51",
        }}
      >
        <span style={{color:'#d65438', fontWeight:700}}>*</span>{props.label}
      </label>
      <div>
        <input
          {...props}
          ref={ref}
          placeholder={props.placeholder}
          className="input-t"
          style={
            props.error
              ? {
                  ...style,
                  border: "1px solid #ff5b5b",
                  boxShadow: "none",
                  background: "#F7F9FC",
                }
              : {
                  ...style,
                  borderRadius:0
                }
          }
        />
      </div>
      {props.error && <p className="validate-error ">{props.error}</p>}
    </div>
  );
});
