import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Button } from "../../../Components/Button/Index";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/hero";
import { Input } from "../../../Components/Input/Index";
import "./index.css";
import user from "../../../Assets/user-image.svg";
import Footer from "../../../Components/Footer/index";
import Password from "antd/es/input/Password";
import config from "../../../config";
import axios from "axios";
import { notification } from "antd";
function Login() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [eye, seteye] = useState(false);
  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Login = async (e) => {
    e.preventDefault();
    if (email && password) {
      setLoad(true);
      const data = {
        email: email?.trim(""),
        password: password?.trim(""),
      };
      axios
        .post(`${config.baseUrl}/login`, data)
        .then((resp) => {
          setLoad(false);
          if (resp.status === 200) {
            sessionStorage.setItem("name", resp.data.user?.name);
            sessionStorage.setItem("token", resp.data.user.token);
            sessionStorage.setItem("user_id", resp.data.user.id);
            sessionStorage.setItem("email", resp.data.user.email);

            window.location.href = "/edd/landing";
          } else {
            Notification("error", "Error", "Login Failed, check credentials!");
          }
        })
        .catch((err) => {
          setLoad(false);
          Notification("error", "Error", "Something went wrong, Please Contact Support!");
        });
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <div className="">
      <TopHead show={false} />
      <Hero />
      <br />
      <br />
      <div className="py-2 px-5">
        <div className="row">
          <div className="col-md-7 col-12">
            <h1 className="edd-lead">Welcome to myEDD</h1>
            <p className="edd-p show-web-interface">
              <div> myEDD connects you to unemployment,</div>
              <div> disability, paid family leave, and benefit</div>
              <div>overpayment services.</div>
            </p>
            <div className="edd-p show-mobile-interface pt-4">
              myEDD connects you to unemployment, disability, paid family leave,
              and benefit overpayment services.
            </div>
            <br />
            <div className="show-web-interface">
              <img src={user} width={"72%"} />
              <br />
              <br />
              <br />
            </div>
          </div>
          <div className="col-md-5 col-12">
            <p
              className="edd-lead"
              style={{
                fontSize: "36px",
                color: "rgb(51, 51, 51)",
                lineHeight: "44px",
              }}
            >
              Log In
            </p>
            <form>
              <div className="mb-4">
                <Input
                  type="text"
                  placeholder=""
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className=" w-100"
                  style={{ background: "#CCBCF7" }}
                />
              </div>
              <div className="mb-1">
                <Input
                  type="password"
                  placeholder=""
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className=" w-100"
                  style={{ background: "#CCBCF7" }}
                />
                {/* <div
                  className="pt-2 public-font"
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "30px",
                    color: "rgb(0, 104, 146)",
                  }}
                >
                  Forgot password?
                </div> */}
              </div>

              <br />
              <Button
                className="dark"
                spinColor={"#ffffff"}
                text="Log In"
                onClick={Login}
                loading={load}
              />
              <br />
            </form>
            <br />
            <br />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12">
            <img src={user} className="show-mobile-interface w-100" />
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default Login;
