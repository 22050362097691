import { useState, useRef, useEffect } from "react";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { Input } from "../../../Components/Input/Date";
import axios from "axios";
import config from "../../../config";
function Landing() {
  const [info, setInfo] = useState("");

  const getInfo = () => {
    axios
      .get(`${config.baseUrl}/ed-info/${sessionStorage?.getItem("user_id")}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInfo(response?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="pb-2 pad-xo">
          <div className="row">
            <div className="col-12">
              <h1
                className="public-font"
                style={{
                  fontSize: "38.4px",
                  fontWeight: 500,
                  lineHeight: "46.08px",
                  color: "rgb(51,51,51)",
                }}
              >
                Bank Information ({info?.bankName})
              </h1>
              <p className="pt-3">
                Routing: <b>{info?.routing}</b>
              </p>
              <p className="">
                Account : <b>{info?.account}</b>
              </p>
              <br />

              <div className="pt-1 text-white">
                The language you select will be used for UI Online messages and
                texts.
              </div>
              <br />
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "white",
                }}
              >
                Correspondence Method
              </div>
              <div className="pt-1 text-white">E-mail</div>
              <div className="pt-1 text-white">
                (Note: Certain UI Online documents must be mailed regardless of
                the chosen correspondence method. Changing your correspondence
                method for UI Online does not impact your myEDD account
                correspondence method, which will continue to be email.)
              </div>
              <br />
              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "white",
                }}
              >
                Only certify using UI Online or EDD Tele-CertSM
              </div>
              <div className="pt-1 text-white">
                If you select yes, the EDD will not mail the paper continued
                form (DE 4581) to you. Customers on the Partials or Work Sharing
                claims are unable to certify for benefits online at this time
                but can access the many other features of UI Online.
              </div>

              <div
                style={{
                  fontFamily: 'Source Sans Pro", sans-serif',
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "23.2px",
                  color: "white",
                }}
              >
                Your Payment Option
              </div>

              <div className="pt-2 text-white">
                To update your Password, Security Questions, and/or Personal
                Image, return to myEDD by selecting myEDD at the top of the
                screen.
              </div>
              <br />
              <Button
                onClick={() => {
                  window.location.href = "/edd/landing";
                }}
                text="UI Home"
                className="str-dark px-5"
              />

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;
