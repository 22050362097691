import { useState, useRef, useEffect } from "react";
import "./index.css";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { Collapse } from "react-collapse";
import config from "../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import warn from "../../../Assets/sjs.png";
import { notification } from "antd";
function Landing() {
  const [openResource, setOpenResource] = useState(false);
  const [openResource2, setOpenResource2] = useState(false);
  const [info, setInfo] = useState({});
  const history = useHistory();

  const getInfo = () => {
    axios
      .get(`${config.baseUrl}/ed-info/${sessionStorage?.getItem("user_id")}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInfo(response?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="pb-2 pad-xo">
          <div className="row">
            <div className="col-12">
              <h1
                className="public-font"
                style={{
                  fontSize: "38.4px",
                  fontWeight: 500,
                  lineHeight: "46.08px",
                  color: "rgb(51,51,51",
                }}
              >
                UI Online Home
              </h1>
              <br />

              <div className="row mb-5">
                <div className="col-md-6 col-12">
                  <div className="edd-top-border">
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "19.2px",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="pr-2">
                          <img src={warn} />
                        </div>
                        {info?.actionReqMain?.length > 2 ? (
                          <div className="pt-1">
                            Action Required [{info?.actionReqMain}]
                          </div>
                        ) : (
                          <div className="pt-1">Action Required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ed-card">
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      You have new action items:
                      <ul
                        className="container pt-3 pb-2 pl-5"
                        style={{ lineHeight: "25.2px" }}
                      >
                        <li>{info?.actionText}</li>
                        <li
                          onClick={() => {
                            Notification(
                              "error",
                              "Error",
                              "Something went wrong! Contact Support!"
                            );
                          }}
                        >
                          <a
                            href="#"
                            style={{
                              borderBottom: "1px dotted ",
                              color: "rgb(4, 107, 153)",
                            }}
                          >
                            {" "}
                            {info?.actionText2}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <h1
                className="public-font"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Notifications
              </h1>
              <br />
              <div
                className="not-collapse"
                onClick={() => {
                  window.location.href = "/edd/backdating";
                }}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      color: "rgb(4, 107, 153)",
                    }}
                  >
                    {info?.message}
                  </div>
                  <i class="fa fa-caret-right"></i>
                </div>
              </div>

              <br />

              <br />
              <h1
                className="public-font"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Claim Status
              </h1>
              <div className="row mb-5 mt-3">
                <div className="col-12">
                  <div className="claim-top-border">
                    <div
                      className="-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "19.2px",
                      }}
                    >
                      {info?.claimStatusTitle}
                    </div>
                  </div>
                  <div className="ed-card-claim">
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      {info?.claimStatusContent}
                    </div>
                    <div
                      className="suns-font pt-3"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      <b>You can reapply</b> for a new claim if you earned{" "}
                      <a
                        href="#"
                        style={{
                          borderBottom: "1px dotted ",
                          color: "rgb(4, 107, 153)",
                        }}
                      >
                        {" "}
                        enough wages
                      </a>{" "}
                      in the last 18 months and are still unemployed or working
                      part time.
                      <a
                        href="#"
                        style={{
                          borderBottom: "1px dotted ",
                          color: "rgb(4, 107, 153)",
                        }}
                      >
                        {" "}
                        Apply online{" "}
                      </a>
                      , and we will notify you when your new claim is processed.
                      This usually takes two to three weeks.
                    </div>
                  </div>
                </div>
              </div>

              <h1
                className="public-font"
                style={{
                  fontSize: "31.04px",
                  fontWeight: 500,
                  lineHeight: "37.248px",
                  color: "rgb(51,51,51",
                }}
              >
                Claim Summary
              </h1>
              <div className="ed-card-claim mt-3 mb-4">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="suns-font">
                      <div className="mb-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          Benefit Year
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          {info?.benefitYear}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          Work Search Requirements{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          {info?.workSearchRequirement}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          Week 1 Certification Status
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          {info?.week1}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          Week 2 Certification Status
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          {info?.week2}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      <div className="mb-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          Last Payment Issued
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          {info?.lastPayment}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          Claim Balance
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "23.2px",
                            color: "rgb(0,0,0)",
                          }}
                        >
                          {info?.claimBalance}
                        </div>
                      </div>

                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "23.2px",
                          color: "rgb(0,0,0)",
                        }}
                      >
                        Weekly Benefit Amount
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "23.2px",
                          color: "rgb(0,0,0)",
                        }}
                      >
                        {info?.weeklyBenefit}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-center show-web-interface">
                  <Button
                    text="View Payment Activity"
                    className="str-dark mr-3"
                    onClick={() => {
                      history.push("/view-payment-activity");
                    }}
                  />
                  <Button
                    text="Click here to submit"
                    className="str-dark"
                    onClick={() => {
                      Notification(
                        "error",
                        "Error",
                        "Something went wrong, Please Contact Support!"
                      );
                    }}
                  />
                </div>
                <div className="show-mobile-interface">
                  <Button
                    text="View Payment Activity"
                    className="str-dark mr-3"
                    onClick={() => {
                      history.push("/view-payment-activity");
                    }}
                  />
                  <Button
                    text="Click here to submit"
                    className="str-dark mt-2"
                    onClick={() => {
                      Notification(
                        "error",
                        "Error",
                        "Something went wrong, Please Contact Support!"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;
