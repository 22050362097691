import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainLand from "../Views/CL/Landing/Index";
import Login from "../Views/CL/Auth/Login";
import UIO from "../Views/CL/UIO/Index";
import PaymentActivity from "../Views/CL/UIO/PaymentActivity";
import ClaimStatus from "../Views/CL/UIO/ClaimStatus";
import BackDating from "../Views/CL/UIO/BackDate";
import Profile from "../Views/CL/Profile/Index";
import BankDetails from "../Views/CL/Profile/bank-details"
export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/edd/landing" exact component={MainLand} />
        <Route path="/bank-details" exact component={BankDetails} />
        <Route path="/" exact component={Login} />
        <Route path="/edd/uio" exact component={UIO} />
        <Route
          path="/view-payment-activity"
          exact
          component={PaymentActivity}
        />
        <Route path="/view-claim-status" exact component={ClaimStatus} />

        <Route path="/profile" exact component={Profile} />
        <Route path="/edd/backdating" exact component={BackDating} />

      </Switch>
    </Router>
  );
}
