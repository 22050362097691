import { useState, useRef, useEffect } from "react";
import "./index.css";
import TopHead from "../../../Components/Header/top-head";
import Hero from "../../../Components/Header/Hero2";
import Footer from "../../../Components/Footer/index";
import { Button } from "../../../Components/Button/Index";
import { useHistory } from "react-router-dom";
function Landing() {
  const history = useHistory();
  return (
    <div className="" style={{ background: "white", height: "100%" }}>
      <TopHead show={true} />
      <Hero show={true} />
      <br />
      <section>
        <div className="py-2 pad-xo">
          <div className="row">
            <div className="col-12">
              <h1
                className="public-font"
                style={{
                  fontSize: "38.4px",
                  fontWeight: 500,
                  lineHeight: "46.08px",
                  color: "rgb(51,51,51)",
                }}
              >
                UI Online
              </h1>
              <br />

              <div className="row mb-5">
                <div className="col-md-6 col-12 mb-4">
                  <div className="edd-top-border-m">
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      File a New Claim
                    </div>
                  </div>
                  <div
                    className="ed-card"
                    style={{
                      border: "1px solid #dddddd",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      Select <b>File New Claim</b> to complete the application.
                      No additional UI Online registration is required to file a
                      claim
                    </div>
                    <div
                      className="pt-3 suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      <b>Note:</b> Our system cannot accept accents or special
                      characters. You can only enter answers with the letters
                      a-z and numbers 0-9.
                    </div>
                    <br />
                    <Button className="claim-btn mb-2" text="File New Claim" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div
                    className="edd-top-border-m"
                   
                  >
                    <div
                      className="-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      Register or Manage Existing Claim
                    </div>
                  </div>
                  <div className="ed-card"  style={{
                      border: "1px solid #dddddd",
                      borderBottom: "1px solid #dddddd",
                    }}>
                    <div
                      className="suns-font"
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "23.2px",
                      }}
                    >
                      Select <b>Register or Manage</b> if you would like to:
                      <ul
                        className="container pl-5"
                        style={{ lineHeight: "25.2px" }}
                      >
                        <li>
                          Register for UI Online with your existing claim.
                        </li>
                        <li>Manage your existing UI Online account.</li>
                      </ul>
                    </div>

                    <Button
                      className="claim-btn mb-2"
                      onClick={() => {
                        window.location.href = "/edd/uio";
                      }}
                      text="Register or Manage"
                    />
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;
