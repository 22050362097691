import React from "react";
import "./index.css";
import classname from "classname";
import { ThreeDots } from "react-loader-spinner";
export const Button = (props) => {
  return (
    <button
      {...props}
      disabled={props.isDisabled ? props.isDisabled : props.loading}
      className={classname(
        props.isDisabled ? "disabled-button" : "button",
        props.small ? "small-button" : "",
        props.color && !props.isDisabled ? props.color : "",
        props.className
      )}
      style={props.style}
      {...props.rest}
    >
      <div className="d-flex align-items-center justify-content-center">
        {props.icon && <img src={props.icon} className="pr-2" />}
        {props.loading ? (
          <ThreeDots
            visible={props.loading}
            height="20"
            width="50"
            color={props.spinColor}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          props.text
        )}
      </div>
    </button>
  );
};
