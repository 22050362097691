import { useEffect, useState } from "react";
import "./index.css";
import Logo from "../../Assets/edd-log.svg";
function Hero({show}) {
  return (
    <>
     <br />
     <br />
      <div className="py-3 px-5 show-web-interface" style={{background:'white', borderBottom:'5px solid #fdb81e'}}>
        <div className="d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img src={Logo} width="" />
          </div>
          {show &&
          <div className="">
            <div className="d-flex align-items-top">
              <div className="pr-5">
                <div>myEDD Home</div>
              </div>
              <div className="pr-5">
                <div>My Profile</div>
              </div>
              <div>
                <div>Log Out</div>
              </div>
            </div>
          </div>}
        </div>
      </div>

      <div className="py-3 px-3 show-mobile-interface" style={{background:'white', borderBottom:'5px solid #fdb81e'}}>
        <div className="d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img src={Logo} width="100" />
          </div>
          {show &&
          <div className="">
            <i className="fa fa-bars fa-2x"></i>
          </div>}
        </div>
      </div>
    </>
  );
}

export default Hero;
